import { parse } from '@conform-to/zod';
import { cssBundleHref } from '@remix-run/css-bundle';
import {
  json,
  type DataFunctionArgs,
  type HeadersFunction,
  type LinksFunction,
  type MetaFunction } from
'@remix-run/node';
import {
  Form,
  Link,
  Links,
  LiveReload,
  Meta,
  NavLink,
  Outlet,
  Scripts,
  ScrollRestoration,
  useFetchers,
  useLoaderData,
  useSubmit } from
'@remix-run/react';
import { withSentry } from '@sentry/remix';
import { useRef } from 'react';
import { AuthenticityTokenProvider } from 'remix-utils/csrf/react';
import { HoneypotProvider } from 'remix-utils/honeypot/react';
import { z } from 'zod';
import { Confetti } from './components/confetti.tsx';
import { GeneralErrorBoundary } from './components/error-boundary.tsx';
import { EpicProgress } from './components/progress-bar.tsx';
import SponsorCarousel from './components/sponsor-carousel.tsx';
import { useToast } from './components/toaster.tsx';
import { Button } from './components/ui/button.tsx';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuPortal,
  DropdownMenuContent,
  DropdownMenuItem } from
'./components/ui/dropdown-menu.tsx';
import { Icon, href as iconsHref } from './components/ui/icon.tsx';
import { EpicToaster } from './components/ui/sonner.tsx';
import fontStyleSheetUrl from './styles/font.css';
import tailwindStyleSheetUrl from './styles/tailwind.css';
import { getImpersonator, getUserId, logout } from './utils/auth.server.ts';
import { ClientHintCheck, getHints, useHints } from './utils/client-hints.tsx';
import { getConfetti } from './utils/confetti.server.ts';
import { csrf } from './utils/csrf.server.ts';
import { prisma } from './utils/db.server.ts';
import { getEnv } from './utils/env.server.ts';
import { honeypot } from './utils/honeypot.server.ts';
import {
  combineHeaders,
  getDomainUrl,
  getUserImgSrc,
  isUserAdmin } from
'./utils/misc.tsx';
import { useNonce } from './utils/nonce-provider.ts';
import { type Theme, setTheme, getTheme } from './utils/theme.server.ts';
import { makeTimings, time } from './utils/timing.server.ts';
import { getToast } from './utils/toast.server.ts';
import { useOptionalUser } from './utils/user.ts';

export const links: LinksFunction = () => {
  return [
  // Preload svg sprite as a resource to avoid render blocking
  { rel: 'preload', href: iconsHref, as: 'image' },
  // Preload CSS as a resource to avoid render blocking
  { rel: 'preload', href: fontStyleSheetUrl, as: 'style' },
  { rel: 'preload', href: tailwindStyleSheetUrl, as: 'style' },
  cssBundleHref ? { rel: 'preload', href: cssBundleHref, as: 'style' } : null,
  { rel: 'mask-icon', href: '/favicons/mask-icon.svg' },
  {
    rel: 'alternate icon',
    type: 'image/png',
    href: '/favicons/favicon-32x32.png'
  },
  { rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png' }, (
  {
    rel: 'manifest',
    href: '/site.webmanifest',
    crossOrigin: 'use-credentials'
  } as const), // necessary to make typescript happy
  //These should match the css preloads above to avoid css as render blocking resource
  { rel: 'icon', type: 'image/svg+xml', href: '/favicons/favicon.svg' },
  { rel: 'stylesheet', href: fontStyleSheetUrl },
  { rel: 'stylesheet', href: tailwindStyleSheetUrl },
  cssBundleHref ? { rel: 'stylesheet', href: cssBundleHref } : null].
  filter(Boolean);
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
  { title: data ? 'IAGT' : 'IAGT' },
  {
    name: 'description',
    content: 'Play competitive golf while having fun and making new friends'
  },
  {
    property: 'og:title',
    content: 'IAGT'
  },
  {
    property: 'og:type',
    content: 'website'
  },
  {
    property: 'og:video',
    content: '/intro-video.mp4'
  },
  {
    property: 'og:image',
    content: '/img/logo-1.png'
  },
  {
    property: 'og:url',
    content: 'https://iagt.golf'
  }];

};

export async function loader({ request }: DataFunctionArgs) {
  const timings = makeTimings('root loader');
  const userId = await time(() => getUserId(request), {
    timings,
    type: 'getUserId',
    desc: 'getUserId in root'
  });

  const user = userId ?
  await time(
    () =>
    prisma.user.findUniqueOrThrow({
      select: {
        id: true,
        firstName: true,
        lastName: true,
        username: true,
        image: { select: { id: true } },
        roles: {
          select: {
            name: true,
            permissions: {
              select: { entity: true, action: true, access: true }
            }
          }
        }
      },
      where: { id: userId }
    }),
    { timings, type: 'find user', desc: 'find user in root' }
  ) :
  null;
  if (userId && !user) {
    console.info('something weird happened');
    // something weird happened... The user is authenticated but we can't find
    // them in the database. Maybe they were deleted? Let's log them out.
    await logout({ request, redirectTo: '/' });
  }
  const { toast, headers: toastHeaders } = await getToast(request);
  const { confettiId, headers: confettiHeaders } = getConfetti(request);
  const honeyProps = honeypot.getInputProps();
  const [csrfToken, csrfCookieHeader] = await csrf.commitToken();
  const impersonator = (await getImpersonator(request))?.user;

  return json(
    {
      user,
      impersonator,
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        userPrefs: {
          theme: getTheme(request)
        }
      },
      ENV: getEnv(),
      toast,
      confettiId,
      honeyProps,
      csrfToken
    },
    {
      headers: combineHeaders(
        { 'Server-Timing': timings.toString() },
        toastHeaders,
        confettiHeaders,
        csrfCookieHeader ? { 'set-cookie': csrfCookieHeader } : null
      )
    }
  );
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  const headers = {
    'Server-Timing': loaderHeaders.get('Server-Timing') ?? ''
  };
  return headers;
};

const ThemeFormSchema = z.object({
  theme: z.enum(['system', 'light', 'dark'])
});

export async function action({ request }: DataFunctionArgs) {
  const formData = await request.formData();
  const submission = parse(formData, {
    schema: ThemeFormSchema
  });
  if (submission.intent !== 'submit') {
    return json(({ status: 'idle', submission } as const));
  }
  if (!submission.value) {
    return json(({ status: 'error', submission } as const), { status: 400 });
  }
  const { theme } = submission.value;

  const responseInit = {
    headers: { 'set-cookie': setTheme(theme) }
  };
  return json({ success: true, submission }, responseInit);
}

function Document({
  children,
  nonce,
  theme = 'light',
  env = {}





}: {children: React.ReactNode;nonce: string;theme?: Theme;env?: Record<string, string>;}) {
  return (
    <html lang="en" className={`${theme} h-full overflow-x-hidden`}>
			<head>
				<ClientHintCheck nonce={nonce} />
				<Meta />
				<meta charSet="utf-8" />
				<meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1" />

				<meta name="og:type" content="website" />
				<Links />
			</head>
			<body className="bg-background text-foreground">
				{children}
				<script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`
          }} />

				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
				<LiveReload nonce={nonce} />
			</body>
		</html>);

}

function App() {
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();
  const theme = useTheme();
  useToast(data.toast);

  return (
    <Document nonce={nonce} theme={theme} env={data.ENV}>
			<div className="flex h-screen flex-col justify-between">
				<Header />

				<div className="mb-10 flex-1 pb-24">
					<Outlet />
				</div>
				<BottomNavBar />
			</div>
			<Confetti id={data.confettiId} />
			<EpicToaster closeButton position="top-center" theme={theme} />
			<EpicProgress />
		</Document>);

}

const Header = () => {
  const { ENV } = useLoaderData<typeof loader>();
  const isProd = ENV.ENVIRONMENT == 'production';
  return (
    <>
			<header className="fixed start-0 top-0 z-20 w-full border-b border-gray-200 bg-iagtDarkBlue py-1">
				<div className="mx-auto flex max-w-2xl flex-col gap-4  sm:mt-2 md:gap-8">
					<div className="flex flex-row items-center justify-between px-2">
						<LeftDropdown />
						<Link to={`/documents`}>
							<Icon
                className="text-body-md h-10 w-10 rounded-sm bg-iagtDarkBlue p-2 text-iagtGold"
                name="file-text" />

						</Link>
						<Link to={'/'}>
							<img
                className="h-12 w-12 sm:h-20 sm:w-20"
                src="/img/logo-1.png"
                alt="IAGT Logo" />

						</Link>
						<a
              href="https://dub.sh/iagt-insta"
              target="_blank"
              rel="noreferrer">

							<Icon
                className="text-body-md h-10 w-10 rounded-sm bg-iagtDarkBlue p-2 text-iagtGold"
                name="instagram" />

						</a>
						<RightDropdown />
					</div>
				</div>
			</header>
			<div className="mb-2 mt-14 sm:mt-24">{isProd && <SponsorCarousel />}</div>
		</>);

};

const LeftDropdown = () => {
  return (
    <DropdownMenu>
			<DropdownMenuTrigger asChild className="bg-iagtDarkBlue">
				<Button
          asChild
          variant="secondary"
          className="bg-iagtGold"
          size={'icon'}>

					<Link
            to={''}
            // this is for progressive enhancement
            onClick={(e) => e.preventDefault()}
            className="flex items-center gap-2">

						<Icon className="text-body-md" name="plus" size={'md'} />
					</Link>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuPortal>
				<DropdownMenuContent sideOffset={8} align="start">
					<DropdownMenuItem asChild>
						<Link prefetch={'viewport'} to={'/create-post'}>
							<Icon className="text-body-md" name={'file-text'}>
								Create Post
							</Icon>
						</Link>
					</DropdownMenuItem>
					<DropdownMenuItem asChild>
						<Link prefetch={'viewport'} to={'/help'}>
							<Icon className="text-body-md" name="question-mark-circled">
								Contact Us
							</Icon>
						</Link>
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenuPortal>
		</DropdownMenu>);

};

function RightDropdown() {
  const user = useOptionalUser();
  const submit = useSubmit();
  const { impersonator } = useLoaderData<typeof loader>();
  const formRef = useRef<HTMLFormElement>(null);

  return (
    <DropdownMenu>
			<DropdownMenuTrigger asChild className="bg-iagtDarkBlue">
				<Button
          asChild
          variant="secondary"
          className="bg-iagtGold"
          size={'icon'}>

					<Link
            to={''}
            // this is for progressive enhancement
            onClick={(e) => e.preventDefault()}
            className="flex items-center gap-2">

						<Icon className="text-body-md" name="hamburger-menu" size={'md'} />
					</Link>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuPortal>
				<DropdownMenuContent sideOffset={8} align="start">
					{impersonator && user &&
          <DropdownMenuItem asChild>
							<Form action="/impersonate" method="POST">
								<input type="hidden" name="intent" value="stop" />
								<Button>
									<Icon className="text-body-md" name="exit">
										Stop Impersonating {user.firstName} {user.lastName}
									</Icon>
								</Button>
							</Form>
						</DropdownMenuItem>}

					<DropdownMenuItem asChild>
						<Link prefetch={'viewport'} to={'/install-app'}>
							<Icon className="text-body-md" name="download">
								Install App
							</Icon>
						</Link>
					</DropdownMenuItem>
					<DropdownMenuItem asChild>
						<Link prefetch={'viewport'} to={'/documents'}>
							<Icon className="text-body-md" name="file-text">
								Documents
							</Icon>
						</Link>
					</DropdownMenuItem>
					<DropdownMenuItem asChild>
						<Link prefetch={'viewport'} to={'/organizers'}>
							<Icon className="text-body-md" name="two-people">
								Organizers
							</Icon>
						</Link>
					</DropdownMenuItem>
					{user ?
          <>
							<DropdownMenuItem asChild>
								<Link prefetch="intent" to={`/settings/profile`}>
									<Icon className="text-body-md" name="pencil-1">
										Edit Profile
									</Icon>
								</Link>
							</DropdownMenuItem>
							<DropdownMenuItem asChild>
								<Link
                to={`/users/${user.username}`}
                // this is for progressive enhancement
                className="flex items-center gap-1"
                prefetch={'viewport'}>

									<img
                  className="h-4 w-4 rounded-full object-cover"
                  alt={user.firstName ?? user.username}
                  src={getUserImgSrc(user.image?.id)} />

									<span className="text-body-md text-iagtDarkBlue">
										{user.firstName ?? user.username}
									</span>
								</Link>
							</DropdownMenuItem>
							<DropdownMenuItem
              asChild
              // this prevents the menu from closing before the form submission is completed
              onSelect={(event) => {
                event.preventDefault();
                submit(formRef.current);
              }}>

								<Form action="/logout" method="POST" ref={formRef}>
									<Icon className="text-body-md" name="exit">
										<button type="submit">Logout</button>
									</Icon>
								</Form>
							</DropdownMenuItem>
							{isUserAdmin(user) &&
            <DropdownMenuItem asChild>
									<Link prefetch="intent" to={`/admin`}>
										<Icon className="text-body-md" name={'cross-1'}>
											Admin Panel
										</Icon>
									</Link>
								</DropdownMenuItem>}

						</> :

          <DropdownMenuItem asChild>
							<Link prefetch="intent" to={'/me'}>
								<Icon className="text-body-md" name="avatar">
									Log in
								</Icon>
							</Link>
						</DropdownMenuItem>}

				</DropdownMenuContent>
			</DropdownMenuPortal>
		</DropdownMenu>);

}

const BottomNavBar = () => {
  const { isPWA } = useHints();

  const navBarClassName = isPWA ? `pb-4 h-20` : `h-16`;
  return (
    <div
      className={`${navBarClassName} fixed bottom-0 left-0 z-50 w-full bg-iagtDarkBlue px-2 text-iagtGold dark:bg-gray-700`}>

			<div className="mx-auto grid h-full max-w-2xl grid-cols-5 font-medium text-iagtGold">
				<NavLink
          prefetch="intent"
          to={`/`}
          className={({ isActive }) =>
          isActive ?
          'group inline-flex flex-col items-center justify-center bg-iagtGold px-5 text-black dark:hover:bg-gray-800' :
          'group inline-flex flex-col items-center justify-center px-5 dark:hover:bg-gray-800'}>


					<button
            type="button"
            className="group inline-flex flex-col items-center justify-center px-5  dark:hover:bg-gray-800">

						<svg
              className="mb-2 h-5 w-5 dark:group-hover:text-blue-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20">

							<path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 8v10a1 1 0 0 0 1 1h4v-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v5h4a1 1 0 0 0 1-1V8M1 10l9-9 9 9" />

						</svg>
						<span className="text-sm  ">Home</span>
					</button>
				</NavLink>
				<NavLink
          prefetch="intent"
          to={`/events`}
          className={({ isActive }) =>
          isActive ?
          'group inline-flex flex-col items-center justify-center bg-iagtGold px-5 text-black dark:hover:bg-gray-800' :
          'group inline-flex flex-col items-center justify-center px-5 dark:hover:bg-gray-800'}>


					<button
            type="button"
            className="group inline-flex flex-col items-center justify-center px-5  dark:hover:bg-gray-800">

						<svg
              className="mb-2 h-5 w-5 dark:text-gray-400 dark:group-hover:text-blue-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20">

							<path
                fill="currentColor"
                d="M6 1a1 1 0 0 0-2 0h2ZM4 4a1 1 0 0 0 2 0H4Zm7-3a1 1 0 1 0-2 0h2ZM9 4a1 1 0 1 0 2 0H9Zm7-3a1 1 0 1 0-2 0h2Zm-2 3a1 1 0 1 0 2 0h-2ZM1 6a1 1 0 0 0 0 2V6Zm18 2a1 1 0 1 0 0-2v2ZM5 11v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 11v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 15v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 15v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 11v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM5 15v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM2 4h16V2H2v2Zm16 0h2a2 2 0 0 0-2-2v2Zm0 0v14h2V4h-2Zm0 14v2a2 2 0 0 0 2-2h-2Zm0 0H2v2h16v-2ZM2 18H0a2 2 0 0 0 2 2v-2Zm0 0V4H0v14h2ZM2 4V2a2 2 0 0 0-2 2h2Zm2-3v3h2V1H4Zm5 0v3h2V1H9Zm5 0v3h2V1h-2ZM1 8h18V6H1v2Zm3 3v.01h2V11H4Zm1 1.01h.01v-2H5v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H5v2h.01v-2ZM9 11v.01h2V11H9Zm1 1.01h.01v-2H10v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM9 15v.01h2V15H9Zm1 1.01h.01v-2H10v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM14 15v.01h2V15h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM14 11v.01h2V11h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM4 15v.01h2V15H4Zm1 1.01h.01v-2H5v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H5v2h.01v-2Z" />

						</svg>
						<span className="text-sm dark:text-gray-400 dark:group-hover:text-blue-500">
							Events
						</span>
					</button>
				</NavLink>
				<NavLink
          prefetch="intent"
          to={`/points`}
          className={({ isActive }) =>
          isActive ?
          'group inline-flex flex-col items-center justify-center bg-iagtGold px-5 text-black dark:hover:bg-gray-800' :
          'group inline-flex flex-col items-center justify-center px-5 dark:hover:bg-gray-800'}>


					<button
            type="button"
            className="group inline-flex flex-col items-center justify-center px-5  dark:hover:bg-gray-800">

						<svg
              className="mb-2 h-5 w-5 dark:text-gray-400 dark:group-hover:text-blue-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 18 16">

							<path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1v14h16m0-9-3-2-3 5-3-2-3 4" />

						</svg>
						<span className="text-sm dark:text-gray-400 dark:group-hover:text-blue-500">
							Points
						</span>
					</button>
				</NavLink>
				<NavLink
          prefetch="intent"
          to={`/users`}
          className={({ isActive }) =>
          isActive ?
          'group inline-flex flex-col items-center justify-center bg-iagtGold px-5 text-black dark:hover:bg-gray-800' :
          'group inline-flex flex-col items-center justify-center px-5 dark:hover:bg-gray-800'}>


					<button
            type="button"
            className="group inline-flex flex-col items-center justify-center px-5  dark:hover:bg-gray-800">

						<svg
              className="mb-2 h-5 w-5 dark:text-gray-400 dark:group-hover:text-blue-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 18">

							<path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M14 3a3 3 0 1 1-1.614 5.53M15 12a4 4 0 0 1 4 4v1h-3.348M10 4.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0ZM5 11h3a4 4 0 0 1 4 4v2H1v-2a4 4 0 0 1 4-4Z" />

						</svg>
						<span className="text-sm dark:text-gray-400 dark:group-hover:text-blue-500">
							Players
						</span>
					</button>
				</NavLink>
				<NavLink
          prefetch="intent"
          to={`/sponsor`}
          className={({ isActive }) =>
          isActive ?
          'group inline-flex flex-col items-center justify-center bg-iagtGold px-5 text-black dark:hover:bg-gray-800' :
          'group inline-flex flex-col items-center justify-center px-5 dark:hover:bg-gray-800'}>


					<button
            type="button"
            className="group inline-flex flex-col items-center justify-center px-5  dark:hover:bg-gray-800">

						<svg
              className="mb-2 h-5 w-5 dark:text-gray-400 dark:group-hover:text-blue-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 16">

							<path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 2a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1M2 5h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Zm8 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z" />

						</svg>
						<span className="text-sm dark:text-gray-400 dark:group-hover:text-blue-500">
							Sponsors
						</span>
					</button>
				</NavLink>
			</div>
		</div>);

};

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  return (
    <AuthenticityTokenProvider token={data.csrfToken}>
			<HoneypotProvider {...data.honeyProps}>
				<App />
			</HoneypotProvider>
		</AuthenticityTokenProvider>);

}

export default withSentry(AppWithProviders);

/**
 * @returns the user's theme preference, or the client hint theme if the user
 * has not set a preference.
 */
export function useTheme() {
  const hints = useHints();
  const optimisticMode = useOptimisticThemeMode();
  if (optimisticMode) {
    return optimisticMode === 'system' ? hints.theme : optimisticMode;
  }
  return 'light';
}

/**
 * If the user's changing their theme mode preference, this will return the
 * value it's being changed to.
 */
export function useOptimisticThemeMode() {
  const fetchers = useFetchers();
  const themeFetcher = fetchers.find((f) => f.formAction === '/');

  if (themeFetcher && themeFetcher.formData) {
    const submission = parse(themeFetcher.formData, {
      schema: ThemeFormSchema
    });
    return submission.value?.theme;
  }
}

export function ErrorBoundary() {
  // the nonce doesn't rely on the loader so we can access that
  const nonce = useNonce();

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <Document nonce={nonce}>
			<GeneralErrorBoundary />
		</Document>);

}