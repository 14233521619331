import { Link } from '@remix-run/react'
import Autoplay from 'embla-carousel-autoplay'
import { SPONSORS } from '#app/sponsors.constants.ts'
import { Avatar, AvatarImage } from './ui/avatar.tsx'
import { Card } from './ui/card.tsx'
import { Carousel, CarouselContent, CarouselItem } from './ui/carousel.tsx'

const SponsorCarousel = () => {
	return (
		<Carousel
			plugins={[
				Autoplay({
					delay: 5000,
					stopOnInteraction: false,
				}),
			]}
			opts={{
				loop: true,
			}}
		>
			<CarouselContent>
				{SPONSORS.map(sponsor => (
					<CarouselItem key={sponsor.header}>
						<Link to={'/sponsor'}>
							<SponsorCardForCarousel {...sponsor} />
						</Link>
					</CarouselItem>
				))}
			</CarouselContent>
		</Carousel>
	)
}

type Props = {
	header: string
	subHeader: string
	imgSrc: string
	companyName: string
}
const SponsorCardForCarousel = ({
	header,
	subHeader,
	imgSrc,
	companyName,
}: Props) => {
	return (
		<div className="justify-center sm:flex">
			<Card className="mx-0 bg-iagtGold sm:w-full">
				<div className="flex max-h-20 items-center px-4 sm:px-8">
					<Avatar className="m-1 h-16 w-16 shadow-xl">
						<AvatarImage
							src={imgSrc}
							alt="sponsor image"
							className="object-contain"
						/>
					</Avatar>
					<div className="flex flex-col gap-0 px-4">
						<div className="sm:text-md text-sm font-bold">{header}</div>
						<div className="sm:text-md text-xs italic">{subHeader}</div>
						<div className="sm:text-md text-xs ">{companyName}</div>
					</div>
				</div>
			</Card>
		</div>
	)
}

export default SponsorCarousel
